<template>
  <div class="product-row">
    <div class="product-image-container">
      <image-component
        :baseUrl="product?.MainPhotoUrl"
        :width="125"
        :height="125"
        :tab-index="0"
        :title="product?.Title"
        :allow-pop-up-on-click="true"
      />
    </div>
    <div class="product-details">
      <div class="product-title">{{ product.Title }}</div>
      <div v-if="hasOrderDetails && hasPOS" class="product-order-history">
        <VTooltip :triggers="['click', 'hover']" placement="top">
          <span>
            Order history&nbsp;
            <i class="fa-solid fa-chart-simple text-primary" />
          </span>
          <template #popper>
            <div v-html="tooltipContent"></div>
          </template>
        </VTooltip>
      </div>
      <div class="product-code">{{ product.ProductCode }}</div>
      <div v-if="hasPOS">
        <input-field
          v-model="localQuantity"
          @inputChanged="updateQuantityField"
          ref="inputFieldRef"
          class="input-width"
          name="quantity-input"
          :value="localQuantity"
          :min-value="0"
          autocomplete="off"
          style="justify-content: center"
        />
      </div>
      <div v-if="hasPOS && localQuantity > 0">
        <button
          ref="popoverButton"
          class="btn btn-sm pop-over-button"
          @click="togglePopover"
        >
          <span>Notes <i class="fas fa-comment text-primary" /></span>
        </button>
        <note-popover
          :target="popoverButton"
          :title="product.Title"
          :isVisible="isPopoverVisible"
          @update:isVisible="isPopoverVisible = $event"
        >
          <textarea
            class="form-control pop-over-text-area"
            v-model="localNotes"
          ></textarea>
          <button class="btn btn-success" @click="saveNote">Save</button>
        </note-popover>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, inject, computed, onMounted } from "vue";
import { storeToRefs } from "pinia";
import { useOrderFormStore } from "@/stores/orderFormStore";
import InputField from "@/components/General/InputField.vue";
import NotePopover from "@/components/Products/ProductCatalog/NotePopover.vue";
import ImageComponent from "@/components/General/ImageComponent.vue";

const props = defineProps({
  product: { type: Object, required: true },
  selectedItem: { type: Object, required: false },
  orderDetails: { type: Array, default: () => [] },
});

const emit = defineEmits(["product-updated"]);

const store = useOrderFormStore();
const { hasPOS } = storeToRefs(store);

const EventBus = inject("EventBus");

const localQuantity = ref(0);
const popoverButton = ref(null);
const isPopoverVisible = ref(false);
const localNotes = ref("");

const hasOrderDetails = computed(
  () => props.orderDetails && props.orderDetails.length > 0
);

const tooltipContent = computed(() => {
  if (!hasOrderDetails.value) return "";
  const formatOrderDetail = (detail) => `
    <div class="order-detail-tooltip">
      <b>${detail.orderDate}</b><br>
      Quantity: <b>${detail.quantity}</b>
    </div>
  `;
  const detailsHtml = props.orderDetails.map(formatOrderDetail).join("<br>");
  return `
    <div>
      <b>Order History</b>
      ${detailsHtml}
    </div>
  `;
});

watch(
  () => props.selectedItem,
  (newValue) => {
    localQuantity.value = newValue ? newValue.quantity : 0;
    localNotes.value = newValue ? newValue.notes : "";
  }
);

const updateQuantityField = (value) => {
  EventBus.$emit("product-catalog-change", {
    quantity: value,
    fieldType: "quantity",
    productCode: props.product.ProductCode,
  });
  setTimeout(() => emit("product-updated"), 50);
};

const togglePopover = () => {
  isPopoverVisible.value = !isPopoverVisible.value;
};

const saveNote = () => {
  EventBus.$emit("product-catalog-change", {
    notes: localNotes.value,
    fieldType: "notes",
    productCode: props.product.ProductCode,
  });
  setTimeout(() => emit("product-updated"), 50);
  isPopoverVisible.value = false;
};

onMounted(() => {
  localQuantity.value = props.selectedItem ? props.selectedItem.quantity : 0;
  localNotes.value = props.selectedItem ? props.selectedItem.notes : "";
});
</script>

<style lang="scss" scoped>
.product-row {
  display: flex;
  align-items: center;
  margin-top: 5px;
  border-bottom: 1px solid $light-dark;
  text-align: center;
}

.product-image-container {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.product-details {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > * {
    width: 100%;
    margin-bottom: 7px;
  }

  .product-title {
    font-weight: 600;
  }
}
</style>