<template>
  <div>
    <div class="modal-header">
      <h6 class="modal-title">Product Catalog</h6>
      <SelectedItemsCount
        :selectedItems="selectedItems"
        :selectedBrand="selectedBrand"
        :selectedCategory="selectedCategory"
        :searchTerm="searchTerm"
        :brands="brands"
        :categories="categories"
      />
      <div
        v-if="isMobile"
        class="mt-2 ps-2 d-flex align-items-center justify-content-start position-absolute"
      >
        <span class="me-1" @click="toggleImageView">Image view</span>
        <input type="checkbox" @click="toggleImageView" :checked="imageView" />
      </div>
      <button type="button" class="btn-close" @click="closeModal"></button>
    </div>
    <div class="modal-nav-bar">
      <div class="row" v-if="isMobile">
        <div class="col-xs-4 col-sm-4 col-md-4">
          <select
            v-model="selectedBrand"
            @change="updateSelectedBrand(selectedBrand)"
            name="brands"
            class="form-select mb-2"
          >
            <option value="">Select Brand</option>
            <option v-for="brand in brands" :key="brand.Id" :value="brand.Id">
              {{ brand.Name }}
            </option>
          </select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4">
          <select
            v-model="selectedCategory"
            @change="updateSelectedCategory"
            name="category"
            class="form-select mb-2"
          >
            <option value="">Select Category</option>
            <option
              v-for="category in categories"
              :key="category.Id"
              :value="category.Id"
            >
              {{ category.Name }}
            </option>
          </select>
        </div>
        <div class="col-xs-4 col-sm-4 col-md-4">
          <input
            v-model="searchTerm"
            type="text"
            name="searchInputCatalog"
            class="form-control mb-2"
            :placeholder="getSearchPlaceHolder"
            @input="updateSearchTerm"
            autocomplete="off"
          />
        </div>
      </div>
      <div class="row" v-else>
        <div class="row">
          <ul class="nav nav-tabs mb-2" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                ref="allBrandButton"
                :class="{ active: selectedBrand === '' }"
                @click="updateSelectedBrand('')"
                type="button"
                tabindex="0"
                role="tab"
              >
                All Brands
              </button>
            </li>
            <li
              class="nav-item"
              role="presentation"
              v-for="brand in brands"
              :key="brand.Id"
            >
              <button
                class="nav-link"
                :class="{ active: selectedBrand === brand.Id }"
                @click="updateSelectedBrand(brand.Id)"
                type="button"
                role="tab"
              >
                {{ brand.Name }}
              </button>
            </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6">
            <select
              v-model="selectedCategory"
              @change="updateSelectedCategory"
              name="category"
              style="max-width: 100%"
              class="form-select mb-2"
            >
              <option value="">Select Category</option>
              <option
                v-for="category in categories"
                :key="category.Id"
                :value="category.Id"
              >
                {{ category.Name }}
              </option>
            </select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6">
            <input
              v-model="searchTerm"
              type="text"
              name="searchInputCatalog"
              class="form-control mb-2"
              :placeholder="getSearchPlaceHolder"
              @input="updateSearchTerm"
              style="max-width: 100%"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from "vue";
import SelectedItemsCount from "@/components/Products/ProductCatalog/SelectedItemsCount.vue";
import { findById } from "@/utils/utils";
const props = defineProps({
  posCode: { type: String, default: "" },
  selectedItems: { type: Array, default: () => [] },
  brands: { type: Array, default: () => [] },
  categories: { type: Array, default: () => [] },
  isMobile: { type: Boolean, default: true },
  resetFilters: { type: Boolean, default: false },
  imageView: { type: Boolean, default: false },
});

const EventBus = inject("EventBus");

const emit = defineEmits([
  "close-modal",
  "update:selectedBrand",
  "update:selectedCategory",
  "update:searchTerm",
  "update:imageView",
]);

const selectedBrand = ref("");
const selectedCategory = ref("");
const searchTerm = ref("");
const allBrandButton = ref(null);
const currentBrand = computed(() =>
  findById(props.brands, selectedBrand.value)
);
const currentCategory = computed(() =>
  findById(props.categories, selectedCategory.value)
);

const getSearchPlaceHolder = computed(() => {
  const brandText = currentBrand.value
    ? `Brand: ${currentBrand.value.Name}`
    : "";
  const categoryText = currentCategory.value
    ? `Category: ${currentCategory.value.Name}`
    : "";
  return brandText || categoryText
    ? `Search in ${brandText}${
        brandText && categoryText ? " and " : ""
      }${categoryText}`
    : "Search for Product by code or title";
});

const closeModal = () => {
  emit("close-modal");
};

const toggleImageView = () => {
  emit("update:imageView", !props.imageView);
};

const updateSelectedBrand = (brandId) => {
  if (!brandId || brandId < 1 || brandId === null || brandId === undefined) {
    selectedBrand.value = "";
  } else {
    selectedBrand.value = brandId;
  }
  selectedCategory.value = "";
  searchTerm.value = "";
  emit("update:selectedBrand", selectedBrand.value);
};

const updateSelectedCategory = () => {
  if (
    !selectedCategory.value ||
    selectedCategory.value === null ||
    selectedCategory.value === undefined ||
    selectedCategory.value < 1
  ) {
    selectedCategory.value = "";
  }
  searchTerm.value = "";
  emit("update:selectedCategory", selectedCategory.value);
};

const updateSearchTerm = () => {
  if (
    !searchTerm.value ||
    searchTerm.value === null ||
    searchTerm.value === undefined
  ) {
    searchTerm.value = "";
  }
  emit("update:searchTerm", searchTerm.value);
};

const clearFilters = () => {
  searchTerm.value = "";
  selectedBrand.value = "";
  selectedCategory.value = "";
};

watch(
  () => props.posCode,
  (newValue, oldValue) => {
    if (oldValue && newValue !== oldValue) {
      clearFilters();
    }
  }
);
onMounted(() => {
  EventBus.$on("order-placed", clearFilters);
});
onBeforeUnmount(() => {
  EventBus.$off("order-placed", clearFilters);
});
</script>